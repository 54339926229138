import React from "react"

const ClientsModule2 = () => {
  return (
    <>
      <div className="w-full justify-center items-center flex flex-col pb-3">
        <h2 className="text-gray-250 xl:max-w-7xl mx-auto mb-0 text-center homepage-heading px-3">
          Trusted by <span className="text-orange">700+</span> companies of all
          sizes, including Fortune 500
        </h2>
      </div>
      <div className="w-full justify-center items-center flex py-9">
        {/* <img src= {imglink} width= {width} height= {height} /> */}
        <picture>
          <source
            loading="lazy"
            srcset="https://res.cloudinary.com/vantagecircle/image/upload/w_1000/q_auto/gatsbycms/uploads/2023/11/VC-Home-clients-logo.webp"
            media="(min-width: 1200px)"
            width="1000"
          />

          <source
            loading="lazy"
            srcset="https://res.cloudinary.com/vantagecircle/image/upload/w_600/q_auto/gatsbycms/uploads/2023/11/VC-Home-clients-logo.webp"
            media="(min-width: 640px)"
            width="600"
          />

          <img
            loading="lazy"
            src="https://res.cloudinary.com/vantagecircle/image/upload/w_275/q_auto/gatsbycms/uploads/2023/11/VC-Home-clients-mobile.webp"
            alt="A description of the image."
            width="275"
          />
        </picture>
      </div>
    </>
  )
}

export default ClientsModule2
